@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&display=swap");

* {
	box-sizing: border-box;
	font-family: "Josefin Sans", sans-serif;
}



body {
	margin: 0;
	padding: 0;
	height: 100vh;
}

:root {
	--color-1: hsl(220, 98%, 61%);
	--color-2: linear-gradient hsl(192, 100%, 67%) to hsl(280, 87%, 65%);

	/* ### Light Theme */

	--color-3: hsl(0, 0%, 98%);
	--color-4: hsl(236, 33%, 92%);
	--color-5: hsl(233, 11%, 84%);
	--color-6: hsl(236, 9%, 61%);
	--color-7: hsl(235, 19%, 35%);

	/* ### Dark Theme */

	--color-8: hsl(235, 21%, 11%);
	--color-9: hsl(235, 24%, 19%);
	--color-10: hsl(234, 39%, 85%);
	--color-11: hsl(236, 33%, 92%);
	--color-12: hsl(234, 11%, 52%);
	--color-13: hsl(233, 14%, 35%);
	--color-14: hsl(237, 14%, 26%);
}

#root {

	position: relative;
	height: 100%;
}

.App {
	text-align: center;
	height: 100%;
	position: relative;
}

.light-background{
	background-color: var(--color-3);
}

.dark-background{
	background-color: var(--color-8);
}


.font-light {
	color: var(--color-3);
}

.font-dark {
	color: var(--color-8);
}

h1 {
	font-size: 2rem;
	letter-spacing: 1rem;
}

li {
	list-style: none;
	font-size: 1rem;
	font-weight: 400;
	border-top: 0.5px solid hsl(233, 14%, 35%);
	border-bottom: 0.5px solid hsl(233, 14%, 35%);
	width: 100%;
	padding: 1.5rem;
	text-align: left;
	display: flex;
	align-items: center;
	position: relative;
	cursor: pointer;

}

li:hover .icon-cross{
	display: block;
}

li > input {
	margin-right: 1rem;
}



.background-light {
	width: 100%;
	height: 30%;
	background-image: url("./images/bg-desktop-light.jpg");
	background-repeat: no-repeat;
}

.background-dark {
	width: 100%;
	height: 30%;
	background-image: url("./images/bg-desktop-dark.jpg");
	background-repeat: no-repeat;
}


.nav-header {
	display: flex;
	justify-content: space-between;
	width: 100%;
	text-align: center;
	align-items: center;
}

.icon {
	width: 2rem;
	height: 2rem;
}

.main-container {
	width: 50%;
	margin: auto;
	display: flex;
	flex-direction: column;
	/* height: 100%; */
	align-items: center;
	gap: 2rem;
	padding: 1rem;
	position: absolute;
	left: 50%;
	top: 5%;
	transform: translateX(-50%);
}

.tasks-cont-light,.tasks-cont-dark {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: auto;
	align-items: flex-start;
	border-radius: .5rem;
	overflow: hidden;
}

.sub-li-cont{
	display: flex;
	align-items:  center;
	width: 100%;
}


.icon-cross{
	display: none;
}


.input-cont{
	display: flex;
	width: 100%;
	justify-content: space-between;
	gap: 25px;
	border-radius: 1rem;
}


.input-text{
	width: 100%;
	padding: 1rem 1rem 1rem 4rem;
	font-size: 1.25rem;
	border:none;
	border-radius: 0.5rem;
}

.input-dark{
	background-color: var(--color-9);
	overflow: hidden;
}

.input-light{
	background-color: var(--color-3);
	overflow: hidden;

}

.dark-cont, .tasks-cont-dark{
	background-color: var(--color-9);
}


.light-cont,.tasks-cont-light{
	background-color: var(--color-3);
	border: 1px solid black;
}


input[type=radio]{
	cursor: pointer;
	display: block;
}

.custom-radio{
	width:  1.5rem;
	height: 1.5rem;
	border: 1px solid hsl(233, 14%, 35%);
	border-radius: 50%;
	margin: 0 1.5rem 0 0;
}

.custom-radio:hover{
	cursor: pointer;
}

.custom-radio input[type=radio]{
	display: none;
}

.checked-box{
	height: 1.5rem;
	width: 1.5rem;
	display: none;
}

.checked-box-active{

	display: inline-block;
	height: 1.5rem;
	width: 1.5rem;
	background-image: url("./images/icon-check.svg");
	background-repeat: no-repeat;
	background: url("./images/icon-check.svg"), linear-gradient(rgba(87, 221, 255, 1) , rgba(192, 88, 243, 1));
	background-size:cover;
	border-radius: 50%;
}




.li-checked{
	color: var(--color-12);
	text-decoration: line-through;
}

.buttons-bottom-cont{
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
	padding: .5rem 1rem;
}


.buttons-bottom-cont-small{
	display: flex;
	/* width: 100%; */
	gap: 1rem;
}

.filter-buttons{
	background: none;
	border: none;
	cursor: pointer;
	color: var(--color-12);
	font-size: 1rem;
}

.filter-buttons:focus{
	color: hsl(220, 98%, 61%);
}

.filter-buttons:hover{
	color:hsl(0, 0%, 98%);
}